import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import { FaTree } from 'react-icons/fa';

import ContentContainer from "../ContentContainer/contentcontainer"
import Data from "../../Data/font-page.json"

const Container = styled(BackgroundImage)`
    width: 100%;
    text-align: center;
    background-position: top center;
    .dark-bg{
        background-color: rgba(0, 0, 0, 0.85);
        text-align: center;
    }
    h1{
        font-family: bookmania;
        font-weight: 600;
        font-size: 3.5em;
        line-height: 80px;
        color: white;
        width: auto;
        margin-bottom: 10px;
    }
    .content-box{
       text-align: left;
       display: inline-block; 
       
       margin-top: 20px;
       vertical-align: top;
       width: 48%;
    }
    .content-box:nth-child(odd){
        margin-right: 20px;
    }
    .content-box p{
        color: #858585;
        font-family: open sans, geneva;
        margin-left: 55px;
        width: 100%;
        margin-top: 2px;
        font-weight: 100;
        font-size: 1em;
        width: 80%;
    }
    svg{
        color: #DB995A;
        font-size: 2.2em;
        margin-right: 20px;
        display: inline-block;
        vertical-align: top;
    }
    h3{
        font-family: bookmania;
        font-size: 1.8em;
        font-weight: 600;
        color: white;
        display: inline-block;
        
    }
    .mt-20-m{
        margin-top: 20px;
    }
    @media(max-width: 1120px){
        h1{
            margin-bottom: 40px;
        }
        .content-box{
            margin-right: 0px !important;
            
            margin: auto;
            margin: auto;
            margin-bottom: 40px;
        }
        .mt-20-m{
            margin-top: 0px;
        }
    }
    @media(max-width: 750px){
        .content-box{
            width: 100%;
        }
    }
    @media(max-width: 640px){
        h1{
            font-size: 2.8em;
        }
        .sub{
            font-size: 0.8em;
            margin-bottom: 10px;
        }
    }
    @media(max-width: 510px){
        h1{
            font-size: 2em;
        }
        .sub{
            font-size: 0.8em;
            margin-bottom: 0px;
        }
        h3{
            font-size: 1.4em;
        }
        .content-box{
            width: 350px;
        }
        .content-box p{
            width: 280px;
        }
    }
`

function HomeIconDetails(){
    const data = useStaticQuery(graphql`
    query {
      headerBgImage: file(relativePath: { eq: "hills_2.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container fluid={data.headerBgImage.childImageSharp.fluid}>
            <div className="dark-bg">
                <ContentContainer >
                    <p className="sub">{ Data.details.sub } </p>
                    <h1>{ Data.details.title }</h1>
                    
                        <div className="content-box">
                            <FaTree />
                            <h3>{ Data.details.details[0].title }</h3>
                            <p>{ Data.details.details[0].para }</p>
                        </div>
                        <div className="content-box">
                            <FaTree />
                            <h3>{ Data.details.details[1].title }</h3>
                            <p>{ Data.details.details[1].para } </p>
                        </div>
                    
                    
                        <div className="content-box">
                            <FaTree />
                            <h3>{ Data.details.details[2].title }</h3>
                            <p>{ Data.details.details[2].para }</p>
                        </div>
                        <div className="content-box">
                            <FaTree />
                            <h3>{ Data.details.details[3].title }</h3>
                            <p>{ Data.details.details[3].para } </p>
                        </div>
                    
                </ContentContainer>
            </div>
        </Container>
    )
}

export default HomeIconDetails
