import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    width: 100%;
    margin: auto;
    background-color: white;
    text-align: center;
    h1{
        font-family: bookmania;
        font-weight: 600;
        font-size: 3.5em;
        line-height: 80px;
    }
    p{
        font-family: open sans, geneva;
        font-weight: 100;
        font-size: 1.6em;
        color: #858585;
    }
    .lot-container{
        margin: 40px 0px;
    }
    @media(max-width: 400px){
      h1{
        font-size: 3em;
      }
      p{
        font-size: 1.2em;
      }
    }
    .vimeo{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%
    }
    .vimeo-container{
        padding:56.25% 0 0 0;position:relative;
    }
`


function HomeVideo(props){
    
    return(
        <Container>
          <ContentContainer>  
                <div className="video-container">
                    <div className="vimeo-container" >
                        <iframe src={"https://player.vimeo.com/video/" + props.videoId + "?color=ff9800&title=0"} className="vimeo"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
          </ContentContainer>
        </Container>
    )
}

export default HomeVideo
