import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import ContentContainer from "../ContentContainer/contentcontainer"
import SingleLot from "./singlelot"
import LotOne from "../../Data/lot-1.json"
import LotTwo from "../../Data/lot-2.json"
import LotThree from "../../Data/lot-3.json"
import LotFour from "../../Data/lot-4.json"
import Data from "../../Data/font-page.json"

const Container = styled.div`
    width: 100%;
    margin: auto;
    background-color: white;
    text-align: center;
    h1{
        font-family: bookmania;
        font-weight: 600;
        font-size: 3.5em;
        line-height: 80px;
    }
    p{
        font-family: open sans, geneva;
        font-weight: 100;
        font-size: 1.2em;
        color: #858585;
    }
    .lot-container{
        margin: 40px 0px;
    }
    @media(max-width: 400px){
      h1{
        font-size: 3em;
      }
      p{
        font-size: 1.2em;
      }
    }
`


function HomeLots(){
    const data = useStaticQuery(graphql`
    query {
      img_one: file(relativePath: { eq: "4-creekside-oaks.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_two: file(relativePath: { eq: "5-casa-de-campo.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_three: file(relativePath: { eq: "6-creekside-oaks.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_four: file(relativePath: { eq: "lot-2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_five: file(relativePath: { eq: "creek-2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return(
        <Container>
          <ContentContainer>  
            <p className="sub">{Data.lots.sub}</p>
            <h1>{Data.lots.title}</h1>
   
            <p className="main-paragraph"> {Data.lots.para} </p>
            <div className="lot-container">
                <SingleLot available={false} name="8 Creekside Oaks" ftimg={data.img_five.childImageSharp.fluid}  shortDesc={ LotFour.short_desc} />
                <SingleLot available={LotFour.available} link="/lot-4" name={ LotFour.title} ftimg={data.img_three.childImageSharp.fluid}  shortDesc={ LotFour.short_desc} />
                <SingleLot available={LotThree.available} link="/lot-3" name={ LotThree.title} ftimg={data.img_two.childImageSharp.fluid}  shortDesc={ LotThree.short_desc} /><br />
                <SingleLot available={LotTwo.available} link="/lot-2" name={ LotTwo.title} ftimg={data.img_one.childImageSharp.fluid} shortDesc={ LotTwo.short_desc} />
                <SingleLot available={LotOne.available} link="/lot-1" name={ LotOne.title} ftimg={data.img_four.childImageSharp.fluid} shortDesc={ LotOne.short_desc}  />
            </div>
          </ContentContainer>
        </Container>
    )
}

export default HomeLots
