import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Data from "../../Data/font-page.json" 
import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    width: 100%;
    margin: auto;
    h1{
        font-family: bookmania;
        font-weight: 600;
        font-size: 3.5em;
        line-height: 80px;
        max-width: 500px;
    }
    p{
        font-family: open sans, geneva;
    }
    .right{
        width: 60%;
        display: inline-block;
        @media(max-width: 1220px){
            width: auto;
            
            float: right;
        }
    }
    .fig-cont{
        width: 100%;
        background-color: red;
    }
    .left{
        width: 40%;
        display: inline-block;
        vertical-align: top;
        @media(max-width: 1220px){
            width: 400px;
        }
        
    }
    .sub {
        text-transform: uppercase;
        font-family: open sans;
        font-size: 1.1em;
        font-weight: 400;
        color: #DB995A;
        letter-spacing: 2px;
        margin-bottom: 20px;
        
    }
    .bold{
        font-size: 1.2em;
        font-family: open sans;
        font-weight: 800;
        margin-bottom: 25px;
        max-width: 500px;
    }
    .main-paragraph{
        font-size: 1.2em;
        font-weight: 100;
        max-width: 500px;
    }
    figure{
        position: relative;
        z-index: 1;
        margin-bottom: 2.5rem;
        width: 80%;
        margin: auto;
        float: right;
        @media(max-width: 1220px){
            width: 400px;
            height: 300px;
        }
    }
    figure:before{
        content: "";
        display: block;
        border-style: sold;
        border-color: #DB995A;
        border-wdith: 0;
        position: absolute;
        z-index: 1;
        border-top-width: 1px;
        top: 2.5rem;
        border-right-width: 1px;
        right: 3.8rem;
        botttom: 0;
        left: 0;
    }
    figure:after{
        content: "";
        display: block;
        border-color: #DB995A;
        position: absolute;
        border-style: solid;
        border-width: 1px;
        top: 2.5rem;
        right: 3.8rem;
        bottom: -2.5rem;
        left: -1.1rem;
    }
   @media(max-width: 1220px){
        font-size: 0.8em;
        h1{
            max-width: 350px;
            line-height: 60px;
        }
        .sub{
            margin-bottom: 10px;
        }
   }
   @media(max-width: 940px){
       font-size: 1em;
        
        .right, .left{
            display: block;
            width: 100%;
        }

        .right{
            display: none;
        }
        .sub{
            margin-bottom: 20px;
        }
        h1{
            max-width: 100%;
            margin-bottom: 20px;
            line-height: 70px;
        }
        .bold{
            max-width: 100%
        }
        .main-paragraph{
            max-width: 100%;
        }
        figure{
            margin-top: 60px;
            position: absolute;
            margin-left: 25%;
            display: none;
        }
    }
    @media(max-width: 510px){
        h1{
            font-size: 2.5em;
        }
    }
`


const Image = styled(Img)`
    width: 90%;
    height: 400px;
    margin: auto;
    @media(max-width: 1220px){
        width: 400px;
        height: 300px;
        margin: auto;
    }
    @media(max-width: 940px){

    }
`

function HomeIntro(){
    const data = useStaticQuery(graphql`
    query {
      headerBgImage: file(relativePath: { eq: "creek-1.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container>
          <ContentContainer>
              <div className="left">
                <p className="sub">{ Data.intro_sub }</p>
                <h1>{ Data.intro_main }</h1>
                <p className="bold">{ Data.intro_1 }</p>
                <p className="main-paragraph">{ Data.intro_2 }</p>
              </div>
              <div className="right">
                <figure>
                    <Image fluid={data.headerBgImage.childImageSharp.fluid} /> 
                </figure>
              </div>
          </ContentContainer>
        </Container>
    )
}

export default HomeIntro
