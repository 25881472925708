import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Data from "../../Data/font-page.json"
import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    background-color: #F5F5F4;
    width: 100%;
    margin: auto;
    text-align: center;
    h1{
        font-family: bookmania;
        font-weight: 600;
        font-size: 3.5em;
        line-height: 80px;
    }
    .image-text{
        margin: 100px 0px;
    }
    .half{
        width: 49%;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }
    h2{
        font-family: bookmania;
        font-weight: 600;
        font-size: 2.8em;
        line-height: 80px;
    }
    p{
        font-family: open sans, geneva;
        font-size: 1.1em;
        font-weight: 100;
        
    }
    figure{
        position: relative;
        z-index: 1;
        margin-bottom: 2.5rem;
        width: 550px;
        margin: auto;
        
        @media(max-width: 1220px){
            width: 400px;
            height: 300px;
        }
    }
    figure:before{
        content: "";
        display: block;
        border-style: sold;
        border-color: #DB995A;
        border-wdith: 0;
        position: absolute;
        z-index: 1;
        border-top-width: 1px;
        top: 2.5rem;
        border-right-width: 1px;
        right: 2.5rem;
        botttom: 0;
        left: 0;
    }
    figure:after{
        content: "";
        display: block;
        border-color: #DB995A;
        position: absolute;
        border-style: solid;
        border-width: 1px;
        top: 2.5rem;
        right: 2.5rem;
        bottom: -2.5rem;
        left: -2.5rem;
    }
    .sm{
      display: none;
    }

    @media(max-width: 1150px){
      h1{
        font-size: 3.2em;
      }
      h2{
        margin-top: 20px;
        line-height: 40px;
        font-size: 2.2em;
      }
      .desk{
        display: none;
      }
      .sm{
        display: block;
      }
      .half{
        display: block;
        width: 100%;
        text-align: left;
      }
      figure:before, figure:after{
        border: none;
      }
      .image-text{
        margin: 50px 0px;
      }
      p{
        font-size: 1em;
      }
    }
    @media(max-width: 1000px){
      figure{
        width: 100%;
        height: 300px;
      }
    }
`

const Image = styled(Img)`
    width: 550px;
    height: 400px;
    margin: auto;
    @media(max-width: 1220px){
        width: 400px;
        height: 300px;
        margin: auto;
    }
    @media(max-width: 940px){
        width: 100%;
        margin: auto;
    }
`


function HomeImageText(){
    const data = useStaticQuery(graphql`
    query {
      headerBgImage: file(relativePath: { eq: "creek-2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      second: file(relativePath: { eq: "winery.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      third: file(relativePath: { eq: "render.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container>
          <ContentContainer>
              <p className="sub">{Data.image_text.sub}</p>
              <h1>{Data.image_text.title}</h1>
              <div className="image-text desk">
                  <div className="half">
                    <figure>
                        <Image fluid={data.headerBgImage.childImageSharp.fluid} /> 
                    </figure>
                  </div>
                  <div className="half">
                    <h2>{Data.image_text.sections[0].title}</h2>
                    <p>{Data.image_text.sections[0].para}</p>
                  </div>
              </div>
              <div className="image-text desk">
              <div className="half">
                    <h2>{Data.image_text.sections[1].title}</h2>
                    <p>{Data.image_text.sections[1].para}</p>
                  </div>
                  <div className="half">
                    <figure>
                        <Image fluid={data.second.childImageSharp.fluid} /> 
                    </figure>
                  </div>
              </div>
              <div className="image-text desk">
                  <div className="half">
                    <figure>
                        <Image fluid={data.third.childImageSharp.fluid} /> 
                    </figure>
                  </div>
                  <div className="half">
                    <h2>{Data.image_text.sections[2].title}</h2>
                    <p>{Data.image_text.sections[2].para}</p>
                  </div>
              </div>
              

              <div className="image-text sm">
                  <div className="half">
                    <figure>
                        <Image fluid={data.headerBgImage.childImageSharp.fluid} /> 
                    </figure>
                  </div>
                  <div className="half">
                    <h2>{Data.image_text.sections[0].title}</h2>
                    <p>{Data.image_text.sections[0].para}</p>
                  </div>
              </div>
              <div className="image-text sm">
              <div className="half">
                    <figure>
                        <Image fluid={data.second.childImageSharp.fluid} /> 
                    </figure>
                  </div>
              <div className="half">
                    <h2>{Data.image_text.sections[1].title}</h2>
                    <p>{Data.image_text.sections[1].para}</p>
                  </div>
              </div>
              <div className="image-text sm">
                  <div className="half">
                    <figure>
                        <Image fluid={data.third.childImageSharp.fluid} /> 
                    </figure>
                  </div>
                  <div className="half">
                    <h2>{Data.image_text.sections[2].title}</h2>
                    <p>{Data.image_text.sections[2].para}</p>
                  </div>
              </div>
          </ContentContainer>
        </Container>
    )
}

export default HomeImageText
