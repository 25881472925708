import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeIntro from "../components/HomeIntro/homeintro"
import HomeFeatures from '../components/HomeFeatures/homefeatures'
import HomeVideo from "../components/HomeVideo/homevideo"
import HomeIconDetails from "../components/HomeIconDetails/homeicondetails"
import HomeImageText from "../components/HomeImageText/homeimagetext"
import HomeLots from "../components/HomeLots/homelots"
import Footer from "../components/Footer/footer"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomeIntro />
    <HomeFeatures />
    <HomeVideo  videoId="406411975" />
    <HomeIconDetails /> 
    <HomeImageText />
    <HomeLots />
    <Footer />
  </Layout>
)

export default IndexPage
