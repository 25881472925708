import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"


const Container = styled.div`
    margin: auto;
    background-color: #F5F5F4;
    text-align: left;
    width: 300px;
    padding-bottom: 20px;
    display: inline-block;
    margin-bottom: 20px;
    vertical-align: top;
    &:nth-child(even){
        margin: 0px 80px;
    }
   .available-banner{
       background: #77966D;
       font-family: open sans, geneva;
       font-weight: 100;
       font-size: 1em;
       text-align: center;
       text-transform: uppercase;
       letter-spacing: 4px;
       padding: 10px 0px;
       color: white;
   }
   .sold-banner{
    background: #DB995A;
    font-family: open sans, geneva;
    font-weight: 100;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 10px 0px;
    color: white;
}
   .lot-content{
       width: 90%;
       margin: auto;
       padding-top: 20px;
   }
   h2{
       font-size: 1.8em;
       font-family: bookmania;
       font-weight: 600;
   }
   .lot-content p{
       font-size: 1em;
       font-family: open sans;
       line-height: 25px;
       margin-bottom: 20px;
       height: 140px;
   }
   @media(max-width: 1180px){
    &:nth-child(even){
        margin: 0px 20px;
        margin-bottom: 20px;
    }
   }
`
const LotImage = styled(Img)`
   width: 100%;
   height: 250px;
`

const LotLink = styled(Link)`
   color: #DB995A;
   font-weight: bold;
   text-transform: uppercase;
   letter-spacing: 2px;
   font-size: 0.8em;
  
`

function SingleLot(props){

    return(
        <Container>
            {props.available &&
                <div className="available-banner">
                    Available
                </div>
            }
            {!props.available &&
                <div className="sold-banner">
                    Sold
                </div>
            }
            
            <LotImage fluid={props.ftimg} />
            <div className="lot-content">
                <h2>{props.name}</h2>
                <p> {props.shortDesc} </p>
                <LotLink to={props.link}>View Details</LotLink>
            </div>
        </Container>
    )
}

export default SingleLot