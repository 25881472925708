import React from "react"
import styled from "styled-components"
import ContentContainer from "../ContentContainer/contentcontainer"
import Data from "../../Data/font-page.json"

const Container = styled.div`
    background-color: #F5F5F4;
    width: 100%;
    margin: auto;
    text-align: center;
    h1{
        font-family: bookmania;
        font-weight: 600;
        font-size: 3.5em;
        line-height: 80px;
        margin-bottom: 20px;
    }
 
    h2{
        font-family: bookmania;
        font-weight: 600;
        font-size: 2.8em;
        line-height: 80px;
    }
    p{
        font-family: open sans, geneva;
        font-size: 1.1em;
        font-weight: 100;
        
    }
   
    .sm{
      display: none;
    }
    li{
        
        font-size: 0.9em;
        font-family: open sans;
        font-weight: 600;
        text-align: left;
        margin: auto;
        line-height: 20px;
        padding: 10px 15px;
    }
    ul{
        
        
    }
    .gold{
      color: #DB995A;
      font-size: 2em;
      vertical-align: middle;
    }
   .feature{
        margin: 0px 10px;
        display: inline-block;
        color: white;
        margin-bottom: 20px;
        vertical-align: top;
        background-color: #DB995A;
   }

    @media(max-width: 1150px){
      
    }

`


function HomeFeatures(){
    return(
        <Container>
          <ContentContainer>
              <h1>Amenities</h1>
              <ul>
                {Data.features
                .map((data,index) => {
                return <div className="feature"><li key={`service_${index}`}>  {data}  </li></div>
                })}
              </ul>
          </ContentContainer>
        </Container>
    )
}

export default HomeFeatures
