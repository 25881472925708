import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { FiChevronsDown } from 'react-icons/fi'
import { IoIosMenu } from 'react-icons/io'
import { useState } from 'react';
import Data from "../../Data/font-page.json" 

import SideMenu from "../SideMenu/sidemenu"

const HeaderContainer = styled.div`
    height: 800px;
    width: 100%;
    max-height: 100%;
    background-size: cover;
    background-color: black;
    h1{
      font-family: bookmania, serif;
      color: black;
      font-size: 3.4em;
      font-weight: 600;
      color: white;
      margin-bottom: 25px;
      letter-spacing: 1px;
    }

    p{
      text-transform: uppercase;
      color: white;
      font-family: open sans;
      font-size: 0.8em;
      letter-spacing: 3px;
      margin-bottom: 8px;
    }

    .inner-container{
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      text-align: center;
      position: relative;
      z-index: 3;
    }
    .text-container{
      position: relative;
      top: 230px;
    }
    .navbar-container{
      display: flex;
      position: relative;
      top: 30px;
      margin: auto;
    }
    .logo-title{
      font-family: bookmania, serif;
      font-size: 1.6em;
      color: white;
    }
    .logo-sub{
      font-family: open sans;
      font-size: 0.8em;
      line-height: 12px;
      color: white;
      letter-spacing: 1px;
      
    }
    .menu{
      font-size: 1em;
      cursor: pointer;
      display: ${({ open }) => open ? 'none' : 'block'};
    }
    .nav-block{
      vertical-align: middle;
      display: inline-block;
      flex: 1;
    }
    .mobile-show{
      display: none;
    }
    .menu svg{
      display: inline-block;
      font-size: 1.8em;
      margin-right: 5px;
      vertical-align: top;
      margin-top: -2px;
    }
    .arrow svg{
      color:white;
      font-size: 2.2em;
      margin: auto;
      position: relative;
      top: 210px;
      margin-top: 2px;
      
    }
    .bounce{
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
      width: 20px;
      margin: auto;
    }
    .video-container{
       position: absolute;
       width: 100%;
       height: 100%;
       top: 0;
       left: 0;
       z-index: 2;
    }
    
    video{
        object-fit: cover;
        width: 100%;
        height: 800px;
    }
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }

    @media(max-width: 900px){
      h1{
        font-size: 2.5em;
      }
      p{
        font-size: 0.7em;
      }
      .logo-title{
        font-size: 1.4em;
      }
      .arrow svg{
        top: 120px;
      }
    }

    @media(max-width: 480px){
      h1{
        font-size: 2.4em;
      }
      .mobile-hide{
        display: none;
      }
      .mobile-show{
        display: block;
      }
      .logo-title{
        margin-bottom: 6px;
        line-height: 26px;
        font-size: 1.3em;
      }
      .logo-sub{
        font-size: 0.7em;
      }
      .text-container{
        
      }
      .menu-label{
        display: none;
      }
      .arrow svg{
        top: 100px;
      }
      .logo-title, .logo-sub{
        display: none;
      }
    }
`
const GreenButton = styled(Link)`
    color: white;
    background-color: #77966D;
    font-family: open sans;
    font-size: 0.8em;
    letter-spacing: 3px;
    padding: 16px 32px 16px;
    text-align:center;
    z-index: -1;
    @media(max-width: 900px){
      padding: 13px 22px 13px;
    }
`
const MobileGreenButton = styled(Link)`
    color: white;
    background-color: #77966D;
    font-family: open sans;
    font-size: 0.8em;
    letter-spacing: 3px;
    padding: 16px 32px 16px;
    text-align:center;
    z-index: -1;
    @media(max-width: 900px){
      padding: 13px 22px 13px;
    }
    @media(max-width: 480px){
      padding: 10px 10px 10px;
      font-size: 0.6em;
    }
`

function VideoHeader(){
  const [open, setOpen] = useState(false)

    const data = useStaticQuery(graphql`
    query {
      headerBgImage: file(relativePath: { eq: "creek-1.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    return(
        <HeaderContainer fluid={data.headerBgImage.childImageSharp.fluid}>
          
                <div className="video-container">
                    <video id="bgvid" playsInline autoPlay loop muted>
                    <source src="https://player.vimeo.com/external/406415751.sd.mp4?s=373aba62e59a724402a11c1c6eb974cc6ed0024e&profile_id=165" type="video/mp4" />

                    </video>
                </div>
                <SideMenu open={open} setOpen={setOpen} />
                <div className="inner-container">
            <div className="navbar-container w-11/12">
              <div className=" nav-block text-white text-left ">
                <div className="menu" open={open} onClick={() => setOpen(!open)}>
                  <div className="menu"><IoIosMenu /><span className="menu-label">MENU</span></div>
                </div>
              </div>
              <div className=" nav-block">
                <div className="logo-title">
                  Creekside <br className="mobile-show" />  Oaks Estates
                </div>
                <div className="logo-sub">
                  ALHAMBRA VALLEY, CA
                </div>
              </div>
              <div className=" nav-block text-right">
                <MobileGreenButton to="/contact" className="contact">CONTACT</MobileGreenButton>
              </div>
            </div>
            <div className="text-container">
              <p>Welcome to Creekside Oaks Estates</p>
              <h1>Secluded Luxury Within Reach</h1>
              <GreenButton to="/lots">EXPLORE PROPERTIES</GreenButton>
              <div className="bounce arrow">
                <Link to="/"><FiChevronsDown /></Link>
              </div>
            </div>
          </div>
        </HeaderContainer>
    )
}

export default VideoHeader
